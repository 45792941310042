<template>
  <div>
    <breadCrumb :title="$t('zhu-ce-nin-de-shang-dian')" :menuList="menuList"></breadCrumb>

    <section class="pt-4 mb-4">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-6 col-md-8 mx-auto">
            <form id="shop" class="" >
              <div class="bg-white rounded shadow-sm mb-3">
                <div class="fs-15 fw-600 p-3 border-bottom">
                  {{ $t('ge-ren-xin-xi') }} </div>
                <div class="p-3">
                  <div class="form-group">
                      <label>{{ $t('dian-pu-ming-cheng') }} <span class="text-primary">*</span></label>
                      <input type="text" class="form-control" v-model="form.shopName"  :placeholder="$t('dian-pu-ming-cheng')" >
                  </div>
                  <div class="form-group">
                      <label>{{ $t('ni-de-ming-zi-0') }} <span class="text-primary">*</span></label>
                      <input type="text" class="form-control" v-model="form.username"  :placeholder="$t('xing-ming-0')" >
                  </div>
                  <div class="form-group">
                      <label>{{ $t('ni-de-you-jian') }} <span class="text-primary">*</span></label>
                      <input type="email" class="form-control" v-model="form.email" :placeholder="$t('dian-zi-you-jian')" name="email">
                  </div>
                  <div class="form-group">
                      <label>{{ $t('ni-de-mi-ma') }} <span class="text-primary">*</span></label>
                      <input type="password" class="form-control" v-model="form.password" @input="validatePassword" :placeholder="$t('mi-ma')" name="password">
                    <label v-if="password_rule == 1">{{$t('mi-ma-zhi-xu-zi-mu-shu-zi')}}</label>
                  </div>
                  <div class="form-group">
                      <label>{{ $t('zhong-fu-shu-ru-mi-ma') }} <span class="text-primary">*</span></label>
                      <input type="password" class="form-control" v-model="form.password2" :placeholder="$t('que-ren-mi-ma-0')" name="password_confirmation">
                  </div>
                  <div class="form-group">
                      <label>{{ $t('yao-qing-ma') }} </label>
                      <input type="email" class="form-control" v-model="form.invite" :placeholder="$t('yao-qing-ma')" name="email">
                  </div>
                </div>
              </div>

              <!-- <div class="bg-white rounded shadow-sm mb-4">
                <div class="fs-15 fw-600 p-3 border-bottom">
                  基本信息
                </div>
                <div class="p-3">
                  <div class="form-group">
                    <label>店铺名称 <span class="text-primary">*</span></label>
                    <input type="text" class="form-control" v-model="form.shopName" placeholder="店铺名称" name="name" required>
                  </div>
                  <div class="form-group">
                    <label>地址 <span class="text-primary">*</span></label>
                    <input type="text" class="form-control mb-3" v-model="form.address" placeholder="地址" name="address" required>
                  </div>

                  <div class="form-group">
                    <label>证件类型 <span class="text-primary">*</span></label>
                    <a-select class="form-control" size="large">
                      <a-select-option :value="option.value" v-for="(option, i) in typeList" :key="i">{{option.name}}</a-select-option>
                    </a-select>
                  </div>
                  <div class="form-group">
                    <label>证件正面照 <span class="text-primary">*</span></label>
                    <div class="input-group" data-toggle="aizuploader" data-type="image">
                      <div class="input-group-prepend">
                        <div class="input-group-text bg-soft-secondary font-weight-medium">浏览</div>
                      </div>
                      <div class="form-control file-amount" @click="addFile(1)">{{form.filename1 ? form.filename1 : '选择文件'}}</div>
                    </div>
                    <previewBar :fileList="fileList1"></previewBar>
                    <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
                  </div>


                  <div class="form-group">
                    <label>证件反面照 <span class="text-primary">*</span></label>

                    <div class="input-group" data-toggle="aizuploader" data-type="image">
                      <div class="input-group-prepend">
                        <div class="input-group-text bg-soft-secondary font-weight-medium">浏览</div>
                      </div>
                      <div class="form-control file-amount" @click="addFile(2)">{{form.filename2 ? form.filename2 : '选择文件'}}</div>
                    </div>

                    <previewBar :fileList="fileList2"></previewBar>
                  </div>

                </div>
              </div> -->


              <div class="text-right">
                <button type="button" class="btn btn-primary fw-600" @click="submit">{{ $t('zhu-ce-nin-de-shang-dian-0') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import previewBar from '@/components/preview'
import { sellerRegister } from '@/api/login'
export default {
  components: {
    breadCrumb,
    previewBar
  },
  data() {
    return {
      menuList: [
        {
          title: this.$t('zhu-ce-nin-de-shang-dian-1'),
          path: ''
        },
      ],
      form: {
        username: '',
        email: '',
        password: '',
        password2: '',
        shopName: '',
        address: '',
        type: '',
        filename1: '',
        invite: '',
        filename2: ''
      },
      typeList: [
        {
          value: 1,
          name: this.$t('shen-fen-zheng')
        },
        {
          value: 2,
          name: this.$t('hu-zhao')
        },
      ],
      fileType: '',
      fileList1: [],
      fileList2: []
    }
  },
  computed: {
    password_rule(){
      return this.$store.state.password_rule
    },
  },
  mounted() {
    this.form.invite = this.$route.query.code
  },
  methods: {
    validatePassword() {
      if(this.password_rule != 1) {
        return
      }
      // 只允许字母和数字
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(this.form.password)) {
        // 移除非法字符
        this.form.password = this.form.password.replace(/[^a-zA-Z0-9]/g, '');
      }
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {

    },
    submit() {
      if (!this.form.username) {
        this.$toast.fail(this.$t('qing-shu-ru-xing-ming'))
        return
      }
      if (!this.form.email) {
        this.$toast.fail(this.$t('qing-shu-ru-you-xiang'))
        return
      }
      if (!this.form.password) {
        this.$toast.fail(this.$t('qing-shu-ru-mi-ma'))
        return
      }
      if (!this.form.password2) {
        this.$toast.fail(this.$t('qing-zai-ci-shu-ru-mi-ma'))
        return
      }
      if(this.form.password != this.form.password2) {
        this.$toast.fail(this.$t('liang-ci-mi-ma-bu-tong'))
        return
      }
      let form = new FormData()
      form.append('ShopName', this.form.shopName)
      form.append('FullName', this.form.username)
      form.append('Email', this.form.email)
      form.append('Password', this.form.password)
      form.append('PasswordConfirm', this.form.password2)
      form.append('InvitationCode', this.form.invite)
      sellerRegister(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('zhu-ce-cheng-gong'))
          setTimeout(() => {
            this.$router.push({
              name: 'login'
            })
          }, 1000)
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>